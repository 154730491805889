import React from 'react';

// import "./src/assets/css/preloader.css";
import './src/assets/modules/materialize/materialize.min.css';
import './src/assets/modules/fonts/mdi/materialdesignicons.min.css';
import './src/assets/library/fontawesome/css/all.css';
import './src/assets/css/style.css';

const closeOpenJiraCollector = () => {
	// Look for the open Jira Issue Collector dialog
	// const openDialog = document.querySelector('.collector-dialog');
	const openDialog = document.querySelector('iframe[src*="collector"], .jira-dialog, .aui-dialog2, .collector-container');
	if (openDialog) {
		const iframeId = openDialog.getAttribute('id');
		const iframe = document.getElementById(iframeId); // Replace with the ID of your iframe
		if (iframe && iframe.parentNode) {
			iframe.parentNode.removeChild(iframe);
		}
	}
};

const loadJiraCollector = (collector) =>
	new Promise((resolve, reject) => {
		// Remove any existing script with the same src
		const existingScript = document.querySelector(`script[src*="${collector.key}"]`);
		if (existingScript) {
			existingScript.remove();
		}

		const baseUrl = 'tell-money-support.atlassian.net';
		// Create a new script element
		const script = document.createElement('script');
		script.type = 'text/javascript';
		// eslint-disable-next-line max-len
		script.src = `https://${baseUrl}/s/d41d8cd98f00b204e9800998ecf8427e-T/g2slup/b/9/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=${collector.key}`;

		script.async = true;

		script.onload = () => {
			// Ensure ATL_JQ_PAGE_PROPS is set for the collector
			window.ATL_JQ_PAGE_PROPS = window.ATL_JQ_PAGE_PROPS || {};
			window.ATL_JQ_PAGE_PROPS[collector.key] = {
				triggerFunction(showCollectorDialog) {
					const triggerElement = document.getElementById(collector.triggerId);
					closeOpenJiraCollector();
					if (triggerElement) {
						triggerElement.onclick = function () {
							closeOpenJiraCollector();
							showCollectorDialog();
						};
					}
				},
			};

			resolve();
		};

		script.onerror = () => {
			reject();
		};

		document.head.appendChild(script);
	});

const initializeJiraCollectors = () => {
	const collectors = [
		{
			key: 'e012e0a1', // Replace with your first Issue Collector key
			ref: 'feedback', // ID of the first trigger button
			triggerId: 'trigger-2', // ID of the first trigger button
		},
		{
			key: 'd6434b6d', // Replace with your second Issue Collector key
			ref: 'bug', // ID of the first trigger button
			triggerId: 'trigger-1', // ID of the second trigger button
		},
	];

	collectors.forEach((collector) => {
		loadJiraCollector(collector).then(() => {});
	});
};

export const onClientEntry = () => {
	if (typeof window !== 'undefined') {
		initializeJiraCollectors();
	}
};

export const onRouteUpdate = () => {
	if (typeof window !== 'undefined') {
		initializeJiraCollectors();
	}
};
